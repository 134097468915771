import React, { FC } from 'react';
import styled from 'styled-components';
import { Text } from 'components';
import ReactCountdown from 'react-countdown';
import { smMobile, tablet } from 'styles/breakpoints';
import Background from 'assets/images/black-friday/banner_background_max.png';
import BackgroundXmas from 'assets/images/black-friday/banner_background_xmas.png';
import BackgroundNY from 'assets/images/black-friday/banner_background_ny.png';
import BackgroundValentine from 'assets/images/black-friday/banner_background_valentine.png';

interface BlackFridayBannerProps {
  countdownDiffInMS?: number;
  saleText: string;
  saveText: string;
  percentageText: string;
  spanColor?: string;
  bubbleColor?: string;
  variant: Variant;
  countdownColor?: string;
}

enum Variant {
  'bf' = 'bf',
  'xmas' = 'xmas',
  'ny' = 'ny',
  'valentine' = 'valentine',
}

const BG_IMAGE: { [key in Variant]: string } = {
  bf: Background,
  xmas: BackgroundXmas,
  ny: BackgroundNY,
  valentine: BackgroundValentine,
};

const BG_MOBILE: { [key in Variant]: string | undefined } = {
  bf: '#000000',
  xmas: '#98D0D8',
  ny: '#000000',
  valentine: '#C94644',
};

const Container = styled.div<{ variant: Variant }>`
  width: 100%;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: ${({ variant }) => `url(${BG_IMAGE[variant]})`};
  @media (${tablet}) {
    background-image: none;
    background-color: ${({ variant }) => BG_MOBILE[variant]};
  }
`;

const GridContainer = styled.div`
  width: 100%;
  height: 4.5rem;
  display: grid;
  grid-template-columns: repeat(3, auto);
  align-items: center;
  @media (${tablet}) {
    grid-template-columns: repeat(2, auto);
    padding: 0 1rem;
  }
  max-width: 34rem;
`;

const SaleText = styled(Text)<{ spanColor: string }>`
  font-size: 26px;
  font-weight: 700;
  line-height: 39px;
  letter-spacing: -0.022em;
  text-align: center;
  color: #ffffff;
  span {
    color: ${({ spanColor }) => spanColor};
  }
  @media (${tablet}) {
    font-size: 1.375rem;
    line-height: 150%;
    text-align: left;
  }
  @media ${smMobile} {
    font-size: 1.1rem;
  }
`;

const CountdownText = styled(Text)<{ countdownColor: string }>`
  font-size: 24px;
  font-weight: 700;
  line-height: 31px;
  letter-spacing: -0.024em;
  text-align: center;
  color: ${({ countdownColor }) => countdownColor};
  @media (${tablet}) {
    line-height: 130%;
    margin-top: -12px;
    text-align: left;
  }
`;

const BubbleWrapper = styled.div`
  height: 4.5rem;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (${tablet}) {
    grid-row: span 2;
    justify-content: flex-end;
  }
`;

const SaveBubble = styled.div<{ bubbleColor: string }>`
  height: 7.8125rem;
  background-color: ${({ bubbleColor }) => bubbleColor};
  width: 7.8125rem;
  border-radius: 3.90625rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const SaveUpTo = styled(Text)`
  color: #fff;
  text-align: center;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 800;
  line-height: 150%; /* 1.3125rem */
  letter-spacing: -0.01925rem;
`;

const Percent = styled(Text)`
  color: #fff;
  text-align: center;
  font-size: 2.625rem;
  font-style: normal;
  font-weight: 800;
  line-height: 100%; /* 2.625rem */
  letter-spacing: -0.05775rem;
`;

const renderer = (
  {
    formatted = {
      days: '00',
      hours: '00',
      minutes: '00',
      seconds: '00',
    },
  },
  countdownColor: string,
) => (
  <>
    <CountdownText countdownColor={countdownColor}>
      {formatted.hours}:{formatted.minutes}:{formatted.seconds}
    </CountdownText>
  </>
);

const HOURS = 24;

const BlackFridayBanner: FC<BlackFridayBannerProps> = ({
  countdownDiffInMS = 0,
  saleText,
  saveText,
  percentageText,
  spanColor = '#ec5c3f',
  bubbleColor = '#ec5c3f',
  variant = Variant.bf,
  countdownColor = '#ffffff',
}) => {
  const countdownMS =
    Date.now() + HOURS * 60 * 60 * 1000 - 1000 - countdownDiffInMS;

  return (
    <Container variant={variant}>
      <GridContainer>
        <SaleText
          spanColor={spanColor}
          dangerouslySetInnerHTML={{ __html: saleText }}
        />
        <BubbleWrapper>
          <SaveBubble bubbleColor={bubbleColor}>
            <SaveUpTo>{saveText}</SaveUpTo>
            <Percent>{percentageText}</Percent>
          </SaveBubble>
        </BubbleWrapper>
        <ReactCountdown
          date={countdownMS}
          renderer={props => renderer(props, countdownColor)}
        />
      </GridContainer>
    </Container>
  );
};

export default BlackFridayBanner;
