import React from 'react';
import type { FC } from 'react';
import Text from 'components/Text';
import styled from 'styled-components';

interface ProgressCountProps {
  total: number;
  count: number;
}

const ProgressCount: FC<ProgressCountProps> = ({ total, count }) => (
  <Text type="body" color="dark80">
    <Wrapper>
      <Purple>{count}</Purple>/{total}
    </Wrapper>
  </Text>
);

export default ProgressCount;

const Purple = styled.div`
  color: #fff;
  border-radius: 6.25rem;
  background: #804da1;
  width: 1.25rem;
  height: 1.25rem;
  display: flex;
  flex-direction: column;
  font-size: 0.75rem;
  align-items: center;
  justify-content: center;
}
`;

const Wrapper = styled.div`
  display: flex;
  font-size: 0.75rem;
  align-items: center;
  justify-content: center;
`;
