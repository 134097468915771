import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';

interface LoadingBarProps {
  duration: number;
  onComplete: () => void;
}

const ProgressBar = styled.div`
  height: 10px;
  background-color: #f7f9f8;
  position: relative;
  width: 257px;
  border-radius: 16px;
`;

const ProgressFill = styled.div<{ progress: number; duration: number }>`
  height: 100%;
  background: linear-gradient(90deg, #ff875a 0%, #fdac42 100%);
  position: absolute;
  border-radius: 16px;
  width: ${({ progress }) => progress}%;
  transition: width ${({ duration }) => duration}s linear;
`;

const LoadingBar: FC<LoadingBarProps> = ({ duration, onComplete }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setProgress(prevProgress => {
        const newProgress = prevProgress + 100 / duration;
        return newProgress > 100 ? 100 : newProgress;
      });
    }, 10);

    const timeoutId = setTimeout(() => {
      clearInterval(intervalId);
      onComplete();
    }, duration * 1000);

    return () => {
      clearInterval(intervalId);
      clearTimeout(timeoutId);
    };
  }, [duration, onComplete]);

  return (
    <ProgressBar>
      <ProgressFill progress={progress} duration={duration} />
    </ProgressBar>
  );
};

export default LoadingBar;
